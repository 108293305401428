import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Crea2 from './componenets/crea2/crea2';
import Test from './componenets/test';
import Nav from './componenets/nav';
import Crea1 from './componenets/crea1/crea1';


const Routess=()=>{

    return ( <Router> 
               <Nav/>
                    <Routes> 
                        <Route path="/crea1" element={<Crea1/>} />
                         <Route path="/crea2" element={<Crea2/>}  /> 
                    </Routes> 
            
            </Router> );
        
        
        }

        export default Routess
    
