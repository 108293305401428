
import './App.css';
import Routess from './routes';

function App() {
  return (
    <div className=" ">
      <Routess/>
    </div>
  );
}

export default App;
