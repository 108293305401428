import React from "react";
import { NavLink } from "react-router-dom";


const Nav=()=>{


    return(
        <div className="space-x-10 hidden">
            <NavLink to="/crea1">crea1</NavLink>
            <NavLink to="/crea2">crea2</NavLink>
        </div>
    )
}

export default Nav